@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    color: #333;
    @apply bg-slate-100;

    hr {
        @apply border-t;
        @apply border-gray-300;
    }

    &.dark {
        color: #F0F0F0;
        @apply bg-gray-800;

        hr {
            @apply border-t;
            @apply border-gray-500;
        }
    }

    a.link {
        @apply text-brand-primary border-b border-brand-primary border-dotted;
        &:hover {
            @apply brightness-110 border-solid;
        }
    }

    a.info-link {
        @apply font-bold text-blue-500 items-center leading-none;

        &:hover {
            @apply text-blue-600;
        }

        // Icon
        svg {
            @apply w-4 h-4 ml-1 mb-1 inline;
        }
    }

    &.dark {
        a.info-link {
            @apply text-blue-200;
            &:hover { @apply text-white; }
        }
    }
}