@import url('https://fonts.googleapis.com/css2?family=Asap+Condensed&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind typography;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

th, td {
  @apply p-4;
  @apply text-ellipsis;
}

form label, .label {
  @apply uppercase;
  @apply text-sm;
  @apply font-bold;
  @apply block mb-2 text-sm;
}